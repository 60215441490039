import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

import Layout from "./src/shared/layout";
import React from "react";
import firebase from "gatsby-plugin-firebase";

/*
 * Custom fonts
 * Uncomment below if using custom fonts
 */
// import "./static/fonts/fonts.css";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
