import { BlocksContent, Image, Link } from "@global";
import { Maybe, SanityLinkOrMenuItem } from "@graphql-types";
import React, { Dispatch, SetStateAction } from "react";
import { colors, fontWeights } from "@util/constants";
import { isSanityCtaWithImage, isSanityLink, isSanityMenuItem } from "@util/types";

import { Chevron } from "@util/svg";
import styled from "styled-components";

interface Props {
  menuItems: Maybe<SanityLinkOrMenuItem>[];
  hoverIndex: number;
  setHoverIndex: Dispatch<SetStateAction<number>>;
}

const NavList = styled.li`
  margin-right: 15px;
  font-weight: ${fontWeights.semibold};

  &:last-of-type {
    margin-right: 0;
  }

  .animate {
    position: relative;
    padding: 10px;

    &:after {
      content: "";
      position: absolute;
      width: calc(100% - 20px);
      height: 2px;
      background-color: currentColor;
      left: 50%;
      bottom: 2px;
      transform: translateX(-50%);
      opacity: 0;
      transition: 0.3s ease all;
    }

    &:hover {
      opacity: 1;

      &:after {
        bottom: 5px;
        opacity: 1;
      }
    }
  }
`;

const MegaMenuContainer = styled.div<{ isHovered: boolean }>`
  display: flex;
  opacity: 0;
  flex-flow: row nowrap;
  align-items: flex-end;
  position: absolute;
  left: 0;
  top: 90%;
  background: ${colors.white};
  width: 100%;
  box-shadow: 0px 9px 13px rgba(0, 0, 0, 0.1);
  transition: 0.5s ease top;
  pointer-events: none;
  z-index: 1000;

  ${({ isHovered }) =>
    isHovered &&
    `
    opacity: 1;
    top: 100%;
    pointer-events: initial;
  `}
`;

const MainLinksContainer = styled.div`
  width: 60%;
  padding: 60px 5% 100px;
  align-self: stretch;
  z-index: 1000;

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5%;
  }
`;

const MenuImage = styled.div`
  min-height: 15px;
  margin-bottom: 35px;
  max-width: 200px;

  div {
    pointer-events: none;
  }
`;

const CtaContainer = styled(Link)`
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.5;

    svg {
      transform: translateX(3px);
    }
  }

  div {
    pointer-events: none;
  }
`;

const CtaTitle = styled.div`
  font-size: 26px;
  font-weight: ${fontWeights.bold};
  line-height: 1.2;
  margin-bottom: 10px;

  > div {
    vertical-align: middle;
  }

  svg {
    transition: ease transform 0.3s;
    vertical-align: middle;
  }
`;

const SideLinksContainer = styled.div`
  background: ${colors.main};
  min-height: 350px;
  width: 40%;
  border-radius: 40px 0px 0px 0px;
  padding: 100px;

  ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    color: ${colors.white};
    font-size: 14px;
  }

  .animate {
    padding: 5px 10px;
  }
`;

const DesktopMenu = ({ menuItems, hoverIndex, setHoverIndex }: Props) => {
  return (
    <>
      {menuItems.map((item, i) => {
        if (!item) return;

        if (isSanityLink(item)) {
          return (
            <NavList key={item._key}>
              <Link className="animate" {...item} onMouseEnter={() => setHoverIndex(i)} />
            </NavList>
          );
        }

        if (isSanityMenuItem(item)) {
          const { title, image, links, ctasWithImage } = item;

          return (
            <NavList key={item._key}>
              <button className="animate" onMouseEnter={() => setHoverIndex(i)}>
                {title}
              </button>
              <MegaMenuContainer isHovered={hoverIndex === i}>
                {ctasWithImage && ctasWithImage.length > 0 && (
                  <MainLinksContainer>
                    <MenuImage>{image && <Image data={image} />}</MenuImage>

                    <ul>
                      {ctasWithImage.map(child => {
                        if (!child) return;

                        const childImage = isSanityCtaWithImage(child) ? child.image : null;

                        return (
                          <li key={child._key}>
                            <CtaContainer {...child.link} onClick={() => setHoverIndex(-1)}>
                              <CtaTitle>
                                {childImage ? (
                                  <Image data={childImage} margin="0 0 5px" />
                                ) : (
                                  child.title
                                )}
                                <Chevron color="current" type="right" margin="0 0 0 20px" />
                              </CtaTitle>
                              <BlocksContent maxWidth={250} data={child.blockContent} />
                            </CtaContainer>
                          </li>
                        );
                      })}
                    </ul>
                  </MainLinksContainer>
                )}
                {links && links.length > 0 && (
                  <SideLinksContainer>
                    <ul>
                      {links.map(link => {
                        if (!link) return;

                        return (
                          <li key={link._key}>
                            <Link
                              padding="10px"
                              onClick={() => setHoverIndex(-1)}
                              animatedSvg
                              {...link}
                            >
                              {link.linkText}
                              <Chevron color="current" type="right" margin="0 0 0 20px" />
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </SideLinksContainer>
                )}
              </MegaMenuContainer>
            </NavList>
          );
        }

        return;
      })}
    </>
  );
};

export default DesktopMenu;
